import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import SteveJobsArticle from './stevejobs';
import BillGatesArticle from './billgates';
import Home from "./home"

import './App.css';

function App() {
  return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/stevejobs" element={<SteveJobsArticle />} />
            <Route path="/billgates" element={<BillGatesArticle />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;