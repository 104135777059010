import React from 'react';

import logo from './logo.svg';
import './App.css';

function Home() {
  return (
      <div>
        <header>
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Welcome to Tech Visionaries</h1>
        </header>
        <nav>
          <ul>
            <li>
              <a href="/stevejobs">Steve Jobs</a>
            </li>
            <li>
              <a href="/billgates">Bill Gates</a>
            </li>
          </ul>
        </nav>
      </div>
  );
}

export default Home;
