import logo from './logo.svg';
import './App.css';

function SteveJobsArticle() {
  return (
    <div>
      <header>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>The Extraordinary Life of Steve Jobs</h1>
      </header>
      <main>
        <article>
          <h2>Early Life and Education</h2>
          <p>
            Steven Paul Jobs, known to the world as Steve Jobs, was born on February 24, 1955, in San Francisco, California. He was adopted by Paul and Clara Jobs, a working-class couple from Mountain View, California. This humble beginning was the starting point for a journey that would change the face of technology forever.
          </p>
          <p>
            Jobs attended Homestead High School in Cupertino, California, where he met Steve Wozniak, a fellow technology enthusiast. Their friendship laid the foundation for what would later become Apple Inc. After high school, Jobs went on to study at Reed College in Portland, Oregon, but he dropped out after just six months, as the formal education system didn't align with his interests.
          </p>

          <h2>The Birth of Apple</h2>
          <p>
            In 1976, Steve Jobs, along with Steve Wozniak and Ronald Wayne, co-founded Apple Computer, Inc. in the Jobs family garage. Their first product, the Apple I computer, was a modest success, but it was the introduction of the Apple II in 1977 that truly put Apple on the map.
          </p>
          <p>
            Apple's vision was to make technology accessible to ordinary people, and this vision set it apart from other companies in the industry. The Macintosh, introduced in 1984, with its graphical user interface, was a groundbreaking development in personal computing.
          </p>

          <h2>The Years Away from Apple</h2>
          <p>
            Despite his early success, Jobs faced a series of setbacks, including conflicts with Apple's board of directors. In 1985, he left Apple to start NeXT Inc., a computer platform development company. He also acquired the animation company Pixar, which later produced hit films like Toy Story and Finding Nemo.
          </p>
          <p>
            Jobs' journey wasn't without failures, but his resilience and unwavering belief in his vision led to his eventual return to Apple in 1996, which would mark one of the most remarkable comebacks in corporate history.
          </p>

          <h2>The iPod, iPhone, and Legacy</h2>
          <p>
            Under Steve Jobs' leadership, Apple introduced a series of iconic products, including the iPod, iPhone, and iPad. These devices revolutionized industries and transformed the way we communicate, listen to music, and interact with technology.
          </p>
          <p>
            Steve Jobs passed away on October 5, 2011, but his legacy lives on in the form of Apple Inc., which continues to innovate and push the boundaries of technology, all while adhering to the principles of simplicity and elegance that Jobs held dear.
          </p>
        </article>
      </main>
      <footer>
        <p>Thank you for exploring the life of Steve Jobs with us.</p>
      </footer>
    </div>
  );
}

export default SteveJobsArticle;
