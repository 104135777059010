import logo from './logo.svg';
import './App.css';

function BillGatesArticle() {
  return (
    <div>
      <header>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>The Remarkable Journey of Bill Gates</h1>
      </header>
      <main>
        <article>
          <h2>Early Life and Education</h2>
          <p>
            William Henry Gates III, known as Bill Gates, was born on October 28, 1955, in Seattle, Washington. From a young age, Gates displayed an early interest in computers and programming. He attended the Lakeside School, where he developed his programming skills and demonstrated an aptitude for mathematics.
          </p>
          <p>
            Gates' parents recognized his talent and enrolled him at Harvard University in 1973. However, his passion for computers led him to drop out of Harvard in 1975 to pursue his dream of revolutionizing the computer industry.
          </p>

          <h2>Microsoft and the Personal Computer Revolution</h2>
          <p>
            In 1975, Bill Gates, along with his childhood friend Paul Allen, founded Microsoft. The company's big break came in 1980 when they signed a contract with IBM to provide an operating system for their first personal computer. Microsoft delivered MS-DOS, which became the foundation for their future success.
          </p>
          <p>
            Microsoft's Windows operating system, introduced in 1985, became the standard for personal computers and contributed significantly to the PC revolution. Under Bill Gates' leadership, Microsoft grew rapidly and became a dominant force in the software industry.
          </p>

          <h2>Philanthropy and Beyond</h2>
          <p>
            In 2000, Bill and his then-wife Melinda Gates established the Bill and Melinda Gates Foundation, one of the world's largest private charitable foundations. The foundation is committed to addressing global health, poverty, and education issues and has donated billions to various causes worldwide.
          </p>
          <p>
            Bill Gates stepped down as CEO of Microsoft in 2000 but remained actively involved in the company's leadership. In recent years, he has focused more on philanthropy, addressing global challenges such as disease eradication and climate change.
          </p>

          <h2>Legacy and Impact</h2>
          <p>
            Bill Gates is often credited with shaping the modern technology landscape. His contributions to personal computing and his philanthropic efforts have had a profound impact on society. While he has faced his share of controversies and challenges, his journey from a young computer enthusiast to a billionaire philanthropist is a testament to the power of innovation and determination.
          </p>
        </article>
      </main>
      <footer>
        <p>Thank you for exploring the life and achievements of Bill Gates with us.</p>
      </footer>
    </div>
  );
}

export default BillGatesArticle;
